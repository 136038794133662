<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between" v-if="!loading">
              <div class="breadcrumb-left">
                <h4>
                  Scheduler - candidates of {{examDay.name}}
                </h4>
                <p>
                  <span class="badge badge-primary" v-if="examDay.has_empty_slots"><i class="fa fa-list font-white"></i> Empty slots</span>
                  <span v-if="examDay.is_confirmed" class="badge badge-success"> <i class="fa fa-check font-white"></i> Confirmed</span>
                  <span v-else class="badge badge-warning"><i class="fa fa-ban font-white"></i>Not confirmed</span>
                  <span v-if="!examDay.is_active" class="badge badge-danger" >Inactive</span>
                  <span class="badge badge-warning inline-block" v-if="examDay.examiner_verification_status == 'waiting'"><i class="fa fa-stopwatch font-white"></i> Waiting for examiner confirmation</span>
                  <span class="badge badge-success inline-block mr-2" v-if="examDay.examiner_verification_status == 'confirmed'"><i class="fa fa-check font-white"></i> Examiner availability confirmed</span>
                  <span class="badge badge-success" v-if="examDay.is_account_holder_notified && examDay.is_confirmed"><i class="fa fa-bell-o font-white"></i> Enroller notified</span>
                  <span class="badge badge-warning" v-if="!examDay.is_account_holder_notified  && examDay.is_confirmed"><i class="fa fa-bell-slash font-white"></i> Enroller not notified</span>
                  <span class="badge badge-success" v-if="examDay.is_examiner_notified"><i class="fa fa-bell-o font-white"></i> Examiners notified</span>
                  <span class="badge badge-warning" v-if="!examDay.is_examiner_notified  "><i class="fa fa-bell-slash font-white"></i> Examiners not notified</span>
                  <span class="badge badge-success" v-if="examDay.is_supervisors_notified"><i class="fa fa-bell-o font-white"></i> Supervisors notified</span>
                  <span class="badge badge-warning" v-if="!examDay.is_supervisors_notified"><i class="fa fa-bell-slash font-white"></i> Supervisors not notified</span>
                  <span class=" mt-2 badge badge-success" v-if="examDay.is_private_venue && examDay.is_private_venue_notified"><i class="fa fa-bell-o font-white"></i> Venue notified</span>
                    <span class=" mt-2 badge badge-warning" v-if="examDay.is_private_venue && !examDay.is_private_venue_notified"><i class="fa fa-bell-slash font-white"></i> Venue not notified</span>
                  <span class="badge badge-success" v-if="examDay.is_timetable_printed && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable printed</span>
                  <span class="badge badge-warning" v-if="!examDay.is_timetable_printed  && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable not printed</span>
                  <span class="badge badge-info" v-if="examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results added</span>
                  <span class="badge badge-warning" v-if="!examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results pending</span>
                  <span class="badge badge-role" v-if="examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatched</span>
                  <span class="badge badge-warning" v-if="!examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatch pending</span>
                </p>
                <small  v-if="examDay.is_timetable_printed && examDay.is_confirmed && examDay.time_table_printed_date">Timetable was printed on <b>{{examDay.time_table_printed_date}}</b></small>
            </div>
              <div class="breadcrumb-right">
                <template>
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="manageSchedule(examDay.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-clock"></i>
                                </span>
                          <span class="navi-text">Manage scheduler</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="createOrExamDay(examDay.id)">
                               <span class="navi-icon">
                                   <i class="fas fas fa-edit"></i>
                                </span>
                          <span class="navi-text">Edit schedule details</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="examDay.candidates_count>0">
                        <a class="navi-link" @click="viewExaminerTimeTablePdf(examDay.id)" >
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                          <span class="navi-text">View timetable (examiner)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item" v-if="examDay.candidates_count>0">
                        <a class="navi-link" @click="viewSupervisorTimeTablePdf(examDay.id)">
                               <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                          <span class="navi-text">View timetable (supervisor)</span>
                        </a>
                      </b-dropdown-text>

                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </template>
              </div>
          </div>

            <div class="card-body">

                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
              <div v-if="!loading">
                <div class="row mt-2">
                  <v-col cols="12" md="6">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3">
                              <div class="d-flex justify-space-between">
                                <div>Examiners</div>
                                <div>
                                  <a href="javascript:;" v-if="examDay.chief_examiner_missing" title="Chief examiner is missing">
                                    <i class="fas fa-circle-exclamation"></i>
                                  </a>
                                </div>
                              </div>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                              <td>
                                <div v-if="examDay">
                                  <div class="mt-1" v-if="examDay.examiner_list.length > 0" v-for="(examiner, index) in examDay.examiner_list" :key="index">
                                    <a href="javascript:;" @click="goToExaminerProfile(examiner.id)">
                                      {{examiner.full_name}}
                                    </a>
                                    <span class="badge badge-primary" v-if="examDay.chief_examiner_id==examiner.id">Chief</span>
                                    <span class="badge badge-info" v-if="examDay.trainee_examiner_ids && examDay.trainee_examiner_ids.includes(examiner.id)">Trainee</span>
                                  </div>
                                </div>
                                <div v-if="!examDay.examiner_list">
                                  No examiner added
                                </div>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <table class="table">
                            <thead>
                            <tr class="text-left">
                              <th class="px-3">Supervisors</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                              <tr>
                                <td>
                                  <div v-if="examDay.supervisor_list.length > 0" v-for="(supervisor, index) in examDay.supervisor_list" :key="index">
                                    <a href="javascript:;" @click="goToSupervisorProfile(supervisor.id)">
                                      {{supervisor.full_name}}
                                    </a>
                                  </div>
                                  <div  v-if="!examDay.supervisor_list">
                                    No supervisor added
                                  </div>
                                </td>
                              </tr>

                            </template>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" >
                    <table class="table">
                      <thead>
                      <tr class="text-left">
                        <th class="px-3">Location</th>
                        <th class="px-3">Session</th>
                        <th class="px-3" style="max-width: 400px !important; white-space: pre-wrap;">Exams selection</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template>
                        <tr>
                          <td>
                            <p><b>Location: </b>{{examDay.location_name}}</p>
                            <p><b>Venue: </b>{{examDay.venue_name}}</p>
                            <p><b>Address: </b><span v-html="examDay.venue_full_address"></span></p>
                            <p><b>Room:</b> {{examDay.room_name}}</p>
                          </td>
                          <td>
                            <p><b>Session name: </b>{{examDay.exam_session_name}}</p>
                            <p><b>Exam date: </b>{{examDay.exam_date_formatted}}</p>
                            <p><b>Exam time: </b> {{examDay.exam_start_time ? examDay.exam_start_time : '' }} - {{examDay.exam_end_time ? examDay.exam_end_time : '' }}</p>
                            <p v-if="examDay.name" class="mb-2">
                              <b>Schedule title: </b>{{examDay.name}}
                            </p>
                          </td>
                          <td style="max-width: 400px !important; white-space: pre-wrap;">
                            <div v-if="examDay.instrument_list && examDay.instrument_list.length > 0">
                              <span v-for="(instrument, index) in examDay.instrument_list" :key="index">
                                {{instrument.name}}
                              </span>
                            </div>
                            <div v-else>
                              NA
                            </div>
                            <div v-if="examDay.syllabus_list && examDay.syllabus_list.length > 0">
                               <span v-for="(syllabus, index) in examDay.syllabus_list" :key="index">
                                {{syllabus.name}}
                              </span>
                            </div>
                            <div v-else>
                              NA
                            </div>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </v-col>
                </div>
                <v-row class="search-wrapper border-outline mt-10 mb-5 px-3 py-5 d-sm-flex justify-content-start" no-gutters>
                  <v-col cols="2">
                    <v-text-field
                        label="Candidate name"
                        outlined
                        dense
                        class="mr-2"
                        v-model="search.candidate_name"
                        @input="search.candidate_name = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                        label="Enrolment key"
                        outlined
                        dense
                        class="mr-2"
                        v-model="search.exam_key"
                        @input="search.exam_key = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-text-field>
                  </v-col>


                  <v-col cols="2">
                    <v-text-field
                        label="Enroller name"
                        outlined
                        dense
                        class="mr-2"
                        v-model="search.account_holder_name"
                        @input="search.account_holder_name = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                        label="Enroller email"
                        outlined
                        dense
                        class="mr-2"
                        v-model="search.account_holder_email"
                        @input="search.account_holder_email = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-text-field>
                  </v-col>


                  <v-col cols="2">
                    <v-select
                        label="Scheduling request"
                        outlined
                        dense
                        :items="scheduling_requests"
                        v-model="search.schedule_request_id"
                        class="mr-2"
                        item-text="name"
                        item-value="id"
                        @input="search.schedule_request_id = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-select>
                  </v-col>


                  <v-col cols="2">
                    <v-text-field
                        label="Teacher name"
                        outlined
                        dense
                        v-model="search.teacher_name"
                        @input="search.teacher_name = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                        class="mr-2"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="2" >
                    <v-autocomplete
                        label="Instrument "
                        v-model="search.instrument_id"
                        item-text="name"
                        v-on:keyup.enter="getCandidates()"
                        item-value="id"
                        class="mr-2"
                        :loading="isInstrumentLoading"
                        :items="instruments"
                        outlined
                        clearable
                        dense
                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="2" >
                    <v-autocomplete
                        label="Syllabus "
                        v-model="search.syllabus_id"
                        item-text="name"
                        v-on:keyup.enter="getCandidates()"
                        item-value="id"
                        class="mr-2"
                        :items="syllabuses"
                        outlined clearable
                        :loading="isSyllabusLoading"
                        dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2">
                    <v-autocomplete
                        label="Grades"
                        outlined
                        dense
                        :items="grades"
                        v-model="search.grade_id"
                        item-text="name"
                        item-value="id"
                        class="mr-2"
                        :loading="isGradeLoading"
                        @input="search.grade_id = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                        label="Exam name"
                        outlined
                        dense
                        v-model="search.exam_name"
                        @input="search.exam_name = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                        class="mr-2"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="2">
                    <v-text-field
                        label="Group identifier"
                        outlined
                        dense
                        v-model="search.group_identifier"
                        @input="search.group_identifier = $event !== null ? $event : ''"
                        v-on:keyup.enter="getCandidates()"
                        clearable
                        class="mr-2"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1">
                    <v-select
                      outlined
                      dense
                      :items="sortByValues"
                      item-text="name"
                      item-value="value"
                      label="Sort by"
                      clearable
                      v-model="search.sort_by"
                    ></v-select>
                  </v-col>

                  <v-col class="text-right" cols="1">
                    <v-btn class="btn text-white ml-2 btn-primary ml-0" @click="getCandidates" :loading="loading" dense>
                      Filter
                    </v-btn>
                  </v-col>

                </v-row>
                <div v-if="examDay.is_confirmed" class="row mt-5 mb-1">
                  <v-col cols="9"></v-col>
                  <v-col cols="12" md="3">
                    <div class="d-flex justify-space-between">
                      <v-autocomplete
                          outlined
                          dense
                          v-model="candidateActionSelection"
                          label="Select action"
                          :items="candidateActionLists"
                          item-text="title"
                          item-value="value"
                          class="mr-4"
                      ></v-autocomplete>
                      <v-btn
                          @click="handleActionChange"
                          :loading="candidateActionLoading"
                          :disabled="candidateActionSelection == '' && candidate_ids.length == 0"
                          class="btn btn-primary text-white pl-4 pr-0"
                      >
                        Go
                        <v-icon small elevation="2" class="ml-2" outlined>fas fa-arrow-right</v-icon>&nbsp;
                      </v-btn>
                    </div>
                  </v-col>

                </div>
                <div class="table-responsive">
                  <table v-if="! loading" class="table">
                    <thead>
                    <tr class="text-left">
                      <th class="px-3">
                        <v-checkbox
                            v-if="showCheckAllBox"
                            @click="checkAllCandidates"
                            v-model="checkAllCandidatesFlag"
                        ></v-checkbox>
                      </th>
                      <th class="px-3">Candidate</th>
                      <th class="px-3" style="max-width: 350px !important;">Enrolment info</th>
                      <th class="px-3">Exam info</th>
                      <th class="px-3" style="max-width: 300px">Additional Info </th>
                      <th class="px-3">Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="candidates.length > 0" >
                      <tr v-for="(item, index) in candidates" :key="index" :style="`background:${item.color} !important;`">

                        <td class="px-3">
                          <v-checkbox
                              v-model="candidate_ids"
                              :value="item.id"
                          ></v-checkbox>
                        </td>
                        <td class="px-3" :colspan="item.is_break ? 5 : ''" :class="item.is_break? 'text-center' : ''">
                          <div v-if="item.is_break">
                            {{item.break_title}}
                          </div>
                          <div v-else>
                            <div class="mt-2">
                              {{item.first_name}} {{item.middle_name}} {{item.last_name}}
                            </div>
                            <div class="mt-2"><a href="#" @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{item.exam_key}}</u></a></div>
                            <!--                            <div class="mb-2">-->
                            <!--                              <b>Date of birth:</b> {{item.date_of_birth}}-->
                            <!--                            </div>-->
                            <div class="mb-2">
                              <b>Gender:</b> {{item.gender_text}}
                            </div>
<!--                            <div class="mb-2" v-if="item.preferred_pronouns">-->
<!--                              <b>Preferred pronoun:</b> {{item.preferred_pronouns}}-->
<!--                            </div>-->
                            <div class="mb-2" v-if="item.ameb_candidate_number">
                              <b>AMEB candidate number</b>: {{item.ameb_candidate_number}}
                            </div>
                            <div class="mb-2" v-if="item.lui_number_qld">
                              <b>LUI:</b> {{item.lui_number_qld}}
                            </div>
                            <div class="mb-2" v-if="item.registration_year_qld">
                              <b> Year of registration:</b> {{item.registration_year_qld}}
                            </div>
                            <div class="mb-2" v-if="item.account_holder_full_name">
                              <b> Enroller name:</b> <a href="#" @click="goToEnrolerProfile(item.account_holder_id)"> {{item.account_holder_full_name}}</a>
                            </div>
                            <div class="mb-2"  v-if="item.account_holder_email" >
                              <b>Enroller email: </b> <span v-copy="item.account_holder_email">{{item.account_holder_email}}</span><br/>
                            </div>
                            <div class="mb-2" v-if="item.teacher_full_name">
                              <b>Teacher name: </b>
                              {{item.teacher_full_name}}
                            </div>
                            <div class="mb-2" v-if="item.total_attachments">
                              <b>Candidate Attachments: </b>
                              <span class="badge badge-warning">{{item.total_attachments}}</span>
                            </div>
                            <div class="mb-2">
                              <i class="fa fa-wheelchair mr-5" v-if="item.special_assistance_required"></i>
                              <!--                              <i class="fa fa-clock border border-light" style="height: 15px; margin-right: 5px; font-size: 12px"> {{item.exam_duration}}</i>-->
                              <!--                              <i :class="item.is_grouped?`fa fa-users  ml-5`:`fa fa-user ml-5`"></i>-->
                              <!--                              <i :class="item.is_locked?`fa fa-lock ml-5`:`fa fa-unlock ml-5`" ></i>-->
                            </div>



                          </div>
                        </td>
                        <td style="max-width: 350px !important; white-space: pre-wrap;" v-if="!item.is_break">
                          <div class="mb-2">{{item.exam_name}}</div>

                          <div class="mb-2" v-if="item.notification_method" >
                            <b>Notification method: </b>{{item.notification_method}}
                          </div>

                          <!--                          <div  class="mb-2"  v-if="item.enrolment_date" >-->
                          <!--                            <b>Enrolment & payment date: </b>{{item.enrolment_date}}-->
                          <!--                          </div>-->

                          <!--                          <div class="mb-2" v-if="item.teacher_contact">-->
                          <!--                            <b>Teacher contact: </b>-->
                          <!--                            {{item.teacher_contact}}-->
                          <!--                          </div>-->
                          <!--                          <div class="mb-2" v-if="item.teacher_email">-->
                          <!--                            <b>Teacher email: </b>-->
                          <!--                            {{item.teacher_email}}-->
                          <!--                          </div>-->
                          <div class="mb-2" v-if="item.teacher_school_name">
                            <b>Teacher school: </b>
                            {{item.teacher_school_name}}
                          </div>
                          <div class="mb-2" v-if="item.parent_first_name">
                            <b>Parent name: </b>
                            {{item.parent_first_name}} {{item.parent_last_name}}
                          </div>
                          <!--                          <div class="mb-2" v-if="item.parent_contact">-->
                          <!--                            <b>Parent contact: </b>-->
                          <!--                            {{item.parent_contact}}-->
                          <!--                          </div>-->
                          <!--                          <div class="mb-2" v-if="item.parent_email">-->
                          <!--                            <b>Parent contact: </b>-->
                          <!--                            {{item.parent_email}}-->
                          <!--                          </div>-->
                          <p class="mb-1" v-if="item.invigilator_first_name">
                            <b>Invigilator name: </b>
                            {{item.invigilator_first_name}} {{item.invigilator_last_name}} <br/>
                          </p>
                          <!--                          <div class="mb-2" v-if="item.invigilator_contact">-->
                          <!--                            <b>Invigilator contact: </b>-->
                          <!--                            {{item.invigilator_contact}} <br/>-->
                          <!--                          </div>-->
                          <!--                          <div class="mb-2" v-if="item.invigilator_email">-->
                          <!--                            <b>Invigilator email: </b>-->
                          <!--                            {{item.invigilator_email}}-->
                          <!--                          </div>-->
                          <!--                          <div class="mb-2" v-if="item.invigilator_dob">-->
                          <!--                            <b>Invigilator dob: </b>-->
                          <!--                            {{item.invigilator_dob}}-->
                          <!--                          </div>-->
                          <!--                          <div class="mb-2" v-if="item.education_authority_submission_allowed">-->
                          <!--                            <b>Education authority data submission: </b>-->
                          <!--                            {{item.education_authority_submission_allowed}}-->
                          <!--                          </div>-->
                          <div class="mb-2" v-if="item.collaborative_instrument_id">
                            <b>Collaborative instrument: </b>
                            {{item.collaborative_instrument_id}}
                          </div>
                          <div class="mb-2" v-if="item.special_assistance_remarks_by_user">
                            <b>Scheduling request by enroler: </b>
                            {{item.special_assistance_remarks_by_user}}
                          </div>
                          <div class="mb-2" v-if="item.special_assistance_remarks_by_admin">
                            <b>Scheduling request by admin: </b>
                            {{item.special_assistance_remarks_by_admin}}
                          </div>
                          <div class="mb-1" v-if="item.show_special_assistance_to_examiner">
                            <b>Scheduling request visible to examiner: </b>
                            {{item.show_special_assistance_to_examiner ? 'Yes' : 'No'}}<br/>
                          </div>
                          <div class="mb-2" v-if="item.show_special_assistance_to_examiner">
                            <b>Scheduling request visible to supervisor: </b>
                            {{item.show_special_assistance_to_supervisor ? 'Yes' : 'No'}}<br/>
                          </div>
                          <div class="mb-2" v-if="item.scheduling_request">
                            <b>Scheduling request: </b>
                            {{item.scheduling_request}}
                          </div>
                          <div class="mb-2" v-if="item.scheduling_remarks">
                            <b>Scheduling remarks: </b>
                            {{item.scheduling_remarks}}
                          </div>
                          <div class="mb-2" v-if="item.unsuitable_examiners">
                            <b>Unsuitable examiners: </b>
                            {{item.unsuitable_examiners}}<br/>
                            {{item.unsuitable_examiner_comments}}
                          </div>
                          <div class="mb-2" v-if="item.hand_type">
                            <b>L/R handed: </b>
                            {{item.hand_type}}
                          </div>
                          <div class="mb-2" v-if="item.guitar_selection">
                            <b>Guitar selection: </b>
                            {{item.guitar_selection}}
                          </div>
                          <div class="mb-2" v-if="item.drum_selection">
                            <b>Drum selection: </b>
                            {{item.drum_selection}}
                          </div>
                          <!--                          <div class="mb-2" v-if="item.can_consider_electric_drum_kit">-->
                          <!--                           <b>Consider electric drum kit: </b>-->
                          <!--                           {{item.can_consider_electric_drum_kit}}<br/>-->
                          <!--                          </div>-->
                          <p v-if="item.is_grouped && item.class_exam_identifier!=null" class="badge badge-info"> <b>{{item.class_exam_identifier}}</b></p>
                          <div v-if="item.repertoire_enabled" class="mb-2">
                            <div v-if="item.product_playlist_type == 'file'">
                                    <span v-if="!item.submitted_repertoire_attachment" class="badge badge-warning py-2 mb-2">
                                      Repertoire not submitted
                                    </span>
                              <span v-if="item.submitted_repertoire_attachment" class="badge badge-success mb-2">
                                          Repertoire submitted
                                    </span>
                            </div>
                            <div v-if="item.product_playlist_type == 'form'">
                                  <span v-if="!item.submitted_repertoire_information" class="badge badge-warning py-2 mb-2">
                                     Repertoire not submitted
                                  </span>

                              <span v-if="item.submitted_repertoire_information" class="badge badge-success mb-2">
                                       Repertoire submitted
                                  </span>
                            </div>
                          </div>

                        </td>
                        <td class="px-3" v-if="!item.is_break">
                          <div v-if="examDay.exam_date_formatted" class="mb-2">
                            <b>Exam date: </b>{{examDay.exam_date_formatted}}
                          </div>
                          <div class="mb-2">
                            <b>Exam time: </b>{{item.exam_start_time}} - {{item.exam_end_time}}
                          </div>
<!--                          <div v-if="examDay.name" class="mb-2">-->
<!--                            <b>Schedule title: </b>{{examDay.name}}-->
<!--                          </div>-->
<!--                          <div v-if="examDay.venue_name" class="mb-2">-->
<!--                            <b>Venue: </b>{{examDay.venue_name}}-->
<!--                          </div>-->
                          <div class="mt-4 ml-1" v-html="item.exam_status_badge">
                          </div>

                          <div class="mt-3 ml-1" v-html="item.enrolment_status_badge">
                          </div>

                        </td>
                        <td class="px-3" style="max-width: 300px !important; white-space: pre-wrap;" v-if="!item.is_break && examDay.is_confirmed">
                          <p  v-if="item.mark_obtained" >
                            <b>Mark obtained: </b>{{item.mark_obtained}}<br/>
                          </p>
                          <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                            {{item.report_type=='scanned'? 'Scanned':'SCORE' }}
                        </span>
                          <div v-html="item.readable_status_badge" class="mb-3"></div>
                          <p v-for="(history, index) in item.result_history">
                            <a href="#" @click="previewReport(history)"><i  v-if="history.result_id" class="fas fa-file-pdf"></i> {{history.added_by}}</a>  <br/>
                            <span class="badge badge-warning">
                            {{history.report_type}}
                        </span>
                            <span class="badge badge-primary" v-if="history.is_main">Official</span>
                            <span class="badge badge-warning" v-else>Archival</span>
                            <span class="badge badge-secondary ml-2" v-if="history.is_chief_examiner">Chief examiner</span><br/>

                          </p>
                          <p v-if="item.pending_examiner_result[0] != null" v-for="(pending, i) in item.pending_examiner_result">
                            Pending result - {{pending.first_name}}  {{pending.last_name}}
                          </p>

                          <div v-if="item.is_result_available_to_user  && item.is_main_result_available">
                            <span class="badge badge-success">
                              Result issued
                            </span>
                          </div>
                          <div v-if="!item.is_result_available_to_user">
                            <span class="badge badge-warning">
                              Result not issued
                            </span>
                          </div>
                          <div class="mb-2 mt-2" v-if="item.certificate_print_eligibility">
                            <b>Certificate print eligibility: </b>
                            {{item.certificate_print_eligibility}}
                          </div>
                          <div class="mb-2">
                            <b>Certificate printed: </b>
                            {{item.is_certificate_printed ? 'Yes' : 'No'}}
                          </div>
                          <div class="mb-2" v-if="item.is_certificate_printed">
                            <b>Certificate printed date: </b>
                            {{item.certificate_printed_date}}
                          </div>
                          <div class="mb-2" v-if="!item.is_payment_complete">
                            <span class="badge badge-warning">Pending school pay invoice</span>
                          </div>
                        </td>
                        <td class="px-3" style="max-width: 300px !important; white-space: pre-wrap;" v-else>
                          Exam day has not been confirmed yet
                        </td>
                        <td v-if="!item.is_break">
                          <div >
                            <template>
                              <b-dropdown
                                  size="sm"
                                  variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret
                                  right
                                  no-flip
                              >
                                <template v-slot:button-content>
                                  <i class="ki ki-bold-more-hor"></i>
                                </template>
                                <!--begin::Navigation-->
                                <div class="navi navi-hover min-w-md-250px">
                                  <b-dropdown-text tag="div" class="navi-item">
                                    <a class="navi-link" @click="getCandidateSummary(item.candidate_exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                                </span>
                                      <span class="navi-text">Candidate details</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" class="navi-item">
                                    <a @click="viewAccountHolderInfo(item.owner_id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                      <span class="navi-text">Enroller profile</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" class="navi-item">
                                    <a @click="downloadEmptyReport(item.id)" class="navi-link">
                                      <span class="navi-icon">
                                        <v-icon color="darken-2">fas fa-download</v-icon>
                                      </span>
                                      <span class="navi-text">Download empty report pdf</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" v-if="item.exam_status == 'scheduled' && examDay.is_confirmed" class="navi-item">
                                    <a @click="viewNotificationLetter(item.exam_day_id, item.id)" class="navi-link">
                                      <span class="navi-icon">
                                          <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                      </span>
                                      <span class="navi-text">View Enroller Notification</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div"
                                                   class="navi-item"
                                                   v-if="item.enrolment_status != 'no_show' && !item.has_live_document_report && item.exam_status == 'pending_result' && examDay.is_confirmed"
                                  >
                                    <a class="navi-link" @click="markCandidateAsNoShown(item.id)">
                                      <span class="navi-icon">
                                        <v-icon color="darken-2">fa fa-eye-slash</v-icon>
                                      </span>
                                      <span class="navi-text">Mark as no show</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div"
                                                   class="navi-item"
                                                   v-if="item.enrolment_status != 'withdrawn' && !item.has_live_document_report && item.exam_status == 'pending_result' && examDay.is_confirmed"
                                  >
                                    <a class="navi-link" @click="markCandidateAsWithDrawn(item.id)">
                                      <span class="navi-icon">
                                        <v-icon color="darken-2">fa fa-hand</v-icon>
                                      </span>
                                      <span class="navi-text">Mark as withdrawn</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div"
                                                   class="navi-item"
                                                   v-if="item.enrolment_status != 'completed' && !item.has_live_document_report && item.exam_status == 'pending_result' && examDay.is_confirmed"
                                  >
                                    <a class="navi-link" @click="markCandidateAsCompleted(item.id)">
                                      <span class="navi-icon">
                                        <v-icon color="darken-2">fa fa-check-circle</v-icon>
                                      </span>
                                      <span class="navi-text">Mark as complete</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" class="navi-item" v-if="examDay.is_confirmed">
                                    <a class="navi-link" @click="manageReport(item.exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                      <span class="navi-text">Manage exam results and report</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" class="navi-item" 
                                    v-if="item.result_history && item.result_history.length > 0"
                                    v-for="(history, index) in item.result_history"
                                  >
                                    <a class="navi-link" @click="previewReport(history)">
                                      <span class="navi-icon">
                                          <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                      </span>
                                      <span class="navi-text">Preview report</span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text tag="div" v-if="item.is_result_available_to_user && examDay.is_confirmed" class="navi-item">
                                    <a @click="makeResultUnAvailableToEnroler(item.id)" class="navi-link">
                                      <span class="navi-icon">
                                          <v-icon color="darken-2">fas fa-ban</v-icon>
                                      </span>
                                      <span class="navi-text">Make result unavailable to enroler</span>
                                    </a>
                                  </b-dropdown-text>
                                </div>
                                <!--end::Navigation-->
                              </b-dropdown>
                            </template>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td class="text-center" colspan="10">
                        No enrolments available
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import ScoreService from "@/services/score/score/ScoreService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import CandidateAttachmentService from "@/services/candidate/candidate-attachment/CandidateAttachmentService";
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
import CandidateService from "@/services/candidate/CandidateService";
import SchedulingRequestService from '@/services/practical-exam/scheduling-request/SchedulingRequestService';
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";


const examDay=new ExamDayService();
const score = new ScoreService();
const enrolmentYear=new EnrolmentYearService();
const candidateAttachment=new CandidateAttachmentService();
const candidate = new CandidateService();
const candidatePlayList=new CandidatePlayListService();
const schedulingRequest = new SchedulingRequestService;
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const grade = new GradeService();
const candidatePracticalExamResult=new CandidatePracticalExamResultService();

export default {
  name: "ViewCandidate",
  data() {
    return {
      candidates: [],
      examDay: {},
      examDayId: null,
      total: null,
      title: '',
      perPage: null,
      page: null,
      nominatedForPrizeValues: [
        {name: 'Yes', value: 'yes'},
        {name: 'No', value: 'no'},
      ],
      loading: true,
      scores:[],
      enrolment_years:[],
      currentYear: new Date().getFullYear(),
      checkAllCandidatesFlag: false,
      candidate_ids: [],
      resultAvailableToEnrolerLoading: false,
      showCheckAllBox: true,
      candidateActionLists:[
        {
          title: 'Send result to Enroller',
          value: 'make_available_to_enroler'
        },
        {
          title: 'Remove result for Enroller',
          value: 'make_unavailable_to_enroler'
        },
        {
          title: 'Withdrawn status',
          value: 'mark_as_withdrawn'
        },
        {
          title: 'No show status',
          value: 'mark_as_no_shown'
        },
        {
          title: 'Assessed status',
          value: 'mark_as_assessed'
        },
        {
          title: 'Completed status',
          value: 'mark_as_completed'
        },
        {
          title: 'Certificate printed',
          value: 'mark_as_certificate_printed'
        },
        {
          title: 'Certificate not printed',
          value: 'mark_as_certificate_not_printed'
        },
        {
          title: 'Certificate sent',
          value: 'mark_as_certificate_dispatched'
        },

        {
          title: 'Certificate not sent',
          value: 'mark_as_certificate_not_dispatched'
        },
      ],
      candidateActionSelection: '',
      candidateActionLoading: false,
      search:{
        candidate_name:'',
        exam_key:'',
        account_holder_name:'',
        account_holder_email:'',
        teacher_name:'',
        instrument_id:'',
        syllabus_id:'',
        location_id:'',
        grade_id:'',
        exam_id:'',
        exam_name:'',
        group_identifier:'',
        schedule_request_id:'',
        exam_day_id: '',
        first_name: '',
        last_name: '',
        score_id: '',
        sort_by: ''
      },
      isLoading:false,
      scheduling_requests: [],
      locations: [],
      instruments: [],
      syllabuses: [],
      grades: [],
      isInstrumentLoading: false,
      isSyllabusLoading: false,
      isGradeLoading: false,
      sortByValues:[
        {
          name: 'Scheduling request (ASC)',
          value: 'scheduling_request_asc'
        },
        {
          name: 'Scheduling request (DESC)',
          value: 'scheduling_request_desc'
        },
        {
          name: 'Candidate first name (ASC)',
          value: 'candidate_first_name_asc'
        },
        {
          name: 'Candidate first name (DESC)',
          value: 'candidate_first_name_desc'
        },
        {
          name: 'Teacher name (ASC)',
          value: 'teacher_name_asc'
        },
        {
          name: 'Teacher name (DESC)',
          value: 'teacher_name_desc'
        },
        {
          name: 'Grade - low to high',
          value: 'grade_low_to_high'
        },
        {
          name: 'Grade - high to low',
          value: 'grade_high_to_low'
        },
      ]
    }
  },
  mounted() {
    this.search.exam_day_id = this.$route.params.examDayId;
    this.examDayId = this.$route.params.examDayId;
    this.getCandidates();
    this.getExamDayInfo();
    this.getAllSchedulingRequest();
  },
  methods: {
    previewReport(result_history){
      if(result_history.report_type=='Scanned'){
        if(result_history.file_attachment){
          window.open(result_history.file_attachment.original, "_blank");
        }else{
          this.$snotify.error("Report Not Found");
        }

      }else{
        candidatePracticalExamResult
            .previewExamReport(result_history.result_id)
            .then(response => {

            })
            .catch(error => {
              this.isLoading = false;

            })
      }
    },
    manageSchedule(examDayId){
      this.$router.push({
        name:'exam-day-scheduler',
        params:{examDayId:examDayId}
      })
    },
    createOrExamDay(examDayId){
      if(examDayId==null || examDayId==undefined)
        this.$router.push({
          name:"exam-day-create",
          params: {examDayId:examDayId}
        });
      else
        this.$router.push({
          name:'exam-day-edit',
          params:{examDayId:examDayId}
        })
    },
    viewExaminerTimeTablePdf(examDayId){
      examDay
          .viewExaminerTimeTablePdf(examDayId)
    },
    viewSupervisorTimeTablePdf(examDayId){
      examDay
          .viewSupervisorTimeTablePdf(examDayId)
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    getExamDayInfo(){
      examDay
          .show(this.examDayId)
          .then((response) => {
            this.examDay = response.data.examDay;
            this.getInstruments();
            this.getSyllabuses();
            this.getGrades();
          })
          .catch(() => {});
    },
    getCandidates(){
      this.loading = true;
      examDay
          .getCandidates(this.search, this.page)
          .then(response => {
            
            if(response.data.candidates != null ){
              this.candidates = response.data.candidates;
            }else{
              this.candidates = [];
            }
            
            if(this.candidates.length > 0){
              this.showCheckAllBox = true;
            }else{
              this.showCheckAllBox = false;
            }

          })
          .catch((err) => {
            this.loading = false;
          })
          .finally(()=>{
            this.loading = false;
          });
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    getAllEnrolmentYears() {
      enrolmentYear
          .all()
          .then(response =>{
            this.enrolment_years=response.data;
            this.year = this.enrolment_years.filter(item => item.year == this.currentYear);
            if(this.currentUser.access_type=='score') {
              this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
              this.getCandidates();
            }else{
              if(this.year.length>0){
                this.search.enrolment_year_id=this.year[0].id;
                this.getCandidates();
              }else{
                this.getCandidates();
              }
            }
          })
    },
    viewRepertoireAttachment(candidateId){
      candidateAttachment
          .getByCandidate(candidateId)
          .then((response) => {
            let candidate_attachment = response.data.candidate_attachment;
            if(candidate_attachment.file_type == 'pdf'){
              window.open(candidate_attachment.file_path.original, '_blank');
            }else{
              window.open(candidate_attachment.file_path.thumb, '_blank');
            }
          })
          .catch(() => {});
    },
    viewPlaylistPdf(candidatePlaylistId){
      candidatePlayList
          .viewPlaylistPdf(candidatePlaylistId);
    },
    viewNotificationLetter(examDayId, candidateId){
      candidate
      .viewNotificationLetter(examDayId, candidateId);
    },
    markCandidateAsNoShown(candidateId){
      this.$confirm({
          message: `Are you sure? `,
              button: {
              no: "No",
              yes: "Yes",
          },
          callback: (confirm) => {
          if (confirm) {
              candidate
              .markCandidateAsNoShown(candidateId)
              .then((response) => {
                  this.$snotify.success(`Marked as no show`);
                  this.getCandidates();
              })
              .catch((err) => {
                  this.$snotify.error(`Something went wrong`);
              })
          }
        }
      })
    },
    markCandidateAsWithDrawn(candidateId){
      this.$confirm({
        message: `Are you sure? `,
          button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .markCandidateAsWithDrawn(candidateId)
            .then((response) => {
                this.$snotify.success(`Marked as withdrawn`);
                this.getCandidates();
            })
            .catch((err) => {
                this.$snotify.error(`Something went wrong`);
            })
          }
      }
    })
    },
    markCandidateAsCompleted(candidateId){
        this.$confirm({
            message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
            if (confirm) {
                candidate
                .markCandidateAsCompleted(candidateId)
                .then((response) => {
                    this.$snotify.success(`Marked as completed`);
                    this.getCandidates();
                })
                .catch((err) => {
                    this.$snotify.error(`Something went wrong`);
                })
            }
        }
    })
    },
    manageReport(examKey){
      this.$router.push({
        name:'practical-exam-result-report',
        params: {examKey:examKey}
      })
    },
    checkAllCandidates(){
      if (this.checkAllCandidatesFlag) {
          this.candidates.forEach(item => {
            this.candidate_ids.push(item.id);
          });
      } else {
          this.candidate_ids = [];
      }
    },
    makeResultUnAvailableToEnroler(candidateId){
      this.$confirm({
        message: `Are you sure you want to make this result unavailable to enroler? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .makeResultUnAvailableToEnroler(candidateId)
            .then((response) => {
              this.$snotify.success(`Made result unavailable to enroler`);
              this.getCandidates();
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      }
    )},
    makeMultipleResultsUnavailableToEnroler(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
            .makeMultipleResultUnavailableToEnroler(data)
            .then((response) => {
              this.$snotify.success(`Made result unavailable to enroler`);
              this.getCandidates();
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
              this.candidateActionSelection = '';
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      })
    },
    makeResultAvailableToEnroler(){
      this.$confirm({
        message: `Are you sure you want to perform this action?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
            .makeResultAvailableToEnroler(data)
            .then((response) => {
              this.$snotify.success(`Made result available to enroler`);
              this.getCandidates();
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
              this.candidateActionSelection = '';
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      }
    )},
    markMultipleAsNoShow(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
            .markMultipleAsNoShow(data)
            .then((response) => {
              this.$snotify.success(`Marked successfully as no show`);
              this.getCandidates();
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
              this.candidateActionSelection = '';
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      })
    },
    markMultipleAsWithdrawn(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
            .markMultipleAsWithdrawn(data)
            .then((response) => {
              this.$snotify.success(`Marked successfully as withdrawn`);
              this.getCandidates();
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
              this.candidateActionSelection = '';
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      })
    },
    markMultipleAsCompleted(){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
            .markMultipleAsCompleted(data)
            .then((response) => {
              this.$snotify.success(`Marked successfully as completed`);
              this.getCandidates();
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
              this.candidateActionSelection = '';
            })
            .catch((err) => {
              this.$snotify.error(`Something went wrong`);
              this.candidateActionLoading = false;
              this.checkAllCandidatesFlag = false;
              this.candidate_ids = [];
            })
          }
        }
      })
    },
    markMultipleAsAssessed(){
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              candidate_ids: this.candidate_ids
            };

            candidate
                .markMultipleAsAssessed(data)
                .then((response) => {
                  this.$snotify.success(`Marked as assessed`);
                  this.getCandidates();
                  this.candidateActionLoading = false;
                  this.checkAllCandidatesFlag = false;
                  this.candidate_ids = [];
                  this.candidateActionSelection = '';
                })
                .catch((err) => {
                  this.$snotify.error(`Something went wrong`);
                  this.candidateActionLoading = false;
                  this.checkAllCandidatesFlag = false;
                  this.candidate_ids = [];
                })
          }
        }
      })
    },
    handleActionChange(){

      if (this.candidate_ids.length>0)
      {

        if(this.candidateActionSelection == 'make_available_to_enroler'){
          this.makeResultAvailableToEnroler();
        }
        if(this.candidateActionSelection == 'make_unavailable_to_enroler'){
          this.makeMultipleResultsUnavailableToEnroler();
        }
        if(this.candidateActionSelection == 'mark_as_no_shown'){
          this.markMultipleAsNoShow();
        }
        if(this.candidateActionSelection == 'mark_as_withdrawn'){
          this.markMultipleAsWithdrawn();
        }
        if(this.candidateActionSelection == 'mark_as_completed'){
          this.markMultipleAsCompleted();
        }
        if(this.candidateActionSelection == 'mark_as_assessed'){
          this.markMultipleAsAssessed();
        }
        if(this.candidateActionSelection == 'mark_as_certificate_printed'){
          this.markPrintedDispatched('printed');
        }
        if(this.candidateActionSelection == 'mark_as_certificate_not_printed'){
          this.markPrintedDispatched('not_printed');
        }
        if(this.candidateActionSelection == 'mark_as_certificate_dispatched'){
          this.markPrintedDispatched('dispatched');
        }

        if(this.candidateActionSelection == 'mark_as_certificate_not_dispatched'){
          this.markPrintedDispatched('not_dispatched');
        }
      }
      else
      {
        this.$snotify.error('Please select candidate(s)');
      }


    },
    markPrintedDispatched(action){
      this.$confirm({
        message: `Are you sure you want to perform this action?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidateActionLoading = true;
            let data = {
              action: action,
              candidate_ids: this.candidate_ids
            }
            candidate
                .markCertificatePrintedDispatched(data)
                .then((response) => {
                  this.$snotify.success(`Report updated`);
                  this.getCandidates();
                  this.candidateActionLoading = false;
                })
                .catch((err) => {
                  this.$snotify.error(`Something went wrong`);
                  this.candidateActionLoading = false;
                });
          }
        }
      })

    },
    getAllSchedulingRequest(){
      this.loading = true;
      schedulingRequest
        .getAllSchedulingRequest()
        .then(response => {
          this.loading = false;
          this.scheduling_requests=response.data.scheduling_requests;
        })
        .catch((err) => {

        });
    },
    getInstruments(){
        this.isInstrumentLoading = true;
        instrument
        .getByExamSession(this.examDay.exam_session_id, '')
        .then((res) => {
            if(res.data.data.length > 0){
                this.instruments = res.data.data;
            }
            this.isInstrumentLoading = false;
        })
        .catch((err) => {
            this.isInstrumentLoading = false;
        });
    },

    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: accountHolderId },
      });
    },
    getSyllabuses(){
      this.isSyllabusLoading = true;
      syllabus
      .getByExamSession(this.examDay.exam_session_id, '')
      .then((res) => {
          if(res.data.data.length > 0){
              this.syllabuses = res.data.data;
          }
          this.isSyllabusLoading = false;
      })
      .catch((err) => {
          this.isSyllabusLoading = false;
      });
    },
    getGrades() {
      this.isGradeLoading = true;
      grade
      .getByExamSession(this.examDay.exam_session_id)
      .then(res => {
        if(res.data.data.length > 0){
          this.grades = res.data.data;
        }
        this.isGradeLoading = false;
      })
      .catch((err) => {
        this.isGradeLoading = false;
      });
    },
    downloadEmptyReport(candidateId){
      candidatePracticalExamResult
        .downloadEmptyReport(candidateId)
        .then(response => {

        })
        .catch(error => {
          this.isLoading = false;

        })
    },
    goToEnrolerProfile(enrolerId){
      this.$router.push({
        name: 'accountholder-edit',
        params: {accountholderId: enrolerId}
      })
    },
    goToExaminerProfile(examinerId){
      this.$router.push(
        {
          name: 'examiner-edit',
          params: {
            examinerId: examinerId
          }
        }
      );
    },
    goToSupervisorProfile(supervisorId){
      this.$router.push(
        {
          name: 'supervisor-edit',
          params: {
            supervisorId: supervisorId
          }
        }
      );
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>